import React, { useContext, useEffect, useState } from 'react'
import tick from '../assets/tick.png'
import cross from '../assets/cross.png'
import QuestionOption from './QuestionOption'
import { UserContext } from '../contextApi/AppContext'
const axios = require('axios');

function AnsweredQuestionCard({ setImg, index, img, handleImageUpload, ques, handleNumberInput, handleAddressInput, inputVal, setInputVal, removeInput }) {
    const { questionDatabase, allQuestion, setAllQuestion } = useContext(UserContext)
    const screenWidth = window.innerWidth;
    const [editQues, setEditQues] = useState({})
    const [currentQues, setCurrentQues] = useState({})
    const [isEdit, setIsEdit] = useState(false)

    useEffect(() => {
        setEditQues({ ...ques, ...currentQues })
    }, [currentQues?.ques])

    useEffect(() => {
        setCurrentQues(questionDatabase?.find(data => data?.ques === ques?.ques))
    }, [questionDatabase])

    const handleUpdate = (ques, value) => {
        if (ques?.input && value.length !== 0) {
            if (ques?.hasOwnProperty('money')) {
                const updatedQuestion = allQuestion?.map(quesData => {
                    if (quesData?.ques === ques?.ques) {
                        return { ques: quesData?.ques, value: `$ ${value}` }
                    }
                    else {
                        return quesData
                    }
                })
                setIsEdit(false)
                setAllQuestion(updatedQuestion)
                setEditQues({ ...editQues, value })
                setInputVal("")
            }
            else {
                const updatedQuestion = allQuestion?.map(quesData => {
                    if (quesData?.ques === ques?.ques) {
                        return { ques: quesData?.ques, value }
                    }
                    else {
                        return quesData
                    }
                })
                setIsEdit(false)
                setAllQuestion(updatedQuestion)
                setEditQues({ ...editQues, value })
            }

        }
        else if (!ques?.input && !ques?.fileInput) {
            const updatedQuestion = allQuestion?.map(quesData => {
                if (quesData?.ques === ques?.ques) {
                    return { ques: quesData?.ques, value }
                }
                else {
                    return quesData
                }
            })
            setIsEdit(false)
            setAllQuestion(updatedQuestion)
            setEditQues({ ...editQues, value })
        }
        else if (ques?.fileInput) {
            const updatedQuestion = allQuestion?.map(quesData => {
                if (quesData?.ques === ques?.ques) {
                    return { ques: quesData?.ques, value, isUploaded: 'Uploaded' }
                }
                else {
                    return quesData
                }
            })
            setAllQuestion(updatedQuestion)
            setIsEdit(false)
            setEditQues({ ...editQues, value })
        }
    }

    const handleHideAns = () => {
        if (index !== 1) {
            setIsEdit(!isEdit)
        }
    }



    const handleImageUpdate = (currQues) => {
        const imageData = new FormData();
        imageData.set('key', 'e97c27e983eb562435d83aa603736597');
        imageData.append('image', img);
        axios
            .post('https://api.imgbb.com/1/upload', imageData)
            .then(result => {
                if (result?.data?.data?.display_url) {
                    setImg(null)
                    handleUpdate(currQues, result?.data?.data?.display_url)
                }
            })
    }

    const handleSubmit = (ques, inputVal) => {
        // setAllQuestion([...allQuestion, { ques: ques?.ques, value: inputVal }])
        // fetch(`https://salura-capital-backend.herokuapp.com/updateLeads?_id=${location?.pathname?.split('/')[2]}`, {
        //     method: 'PATCH',
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify({ userInfo, allQuestion: [...allQuestion, { ques: ques?.ques, value: inputVal }], date: dealDate })
        // })
        //     .then(res => {
        //         if (res) {
        //             setIsUpdated(true)
        //             sessionStorage.setItem('allData', JSON.stringify({ userInfo, allQuestion: [...allQuestion, { ques: ques?.ques, value: inputVal }], date: dealDate }))
        //             history.replace('/dashboard')
        //         }
        //     })
    }

    

    return (
        <div className='container' id='ansWerQues'>
            <div className='question my-4 py-4 row justify-content-between Card' >
                <div className='col-1 mt-1'>
                    <p className='circle'>{index}</p>
                </div>
                <div className='col-11'>
                    <div className='row'>
                        <div className='col-9 col-sm-10'>
                            <h4 onClick={() => handleHideAns()} className='mb-0 text-secondary pointer' data-toggle="collapse" data-target={`#collapseEdit${index}`} aria-expanded="true" aria-controls={`collapseEdit${index}`}>{editQues?.ques}</h4>
                            {
                                !isEdit &&
                                <h6 className='my-2 text-secondary'>- {editQues?.value}</h6>
                            }
                        </div>
                        <div className='col-3 col-sm-1 mt-2'>
                            <img src={editQues?.value === 'Skipped' ? cross : tick} className='w-100' alt='tick' />
                        </div>
                    </div>

                    {
                        index !== 1 &&
                        <div className='collapse' id={`collapseEdit${index}`} aria-labelledby="headingOne" data-parent="#ansWerQues">
                            {
                                editQues?.hasOwnProperty('subtxt') &&
                                <p className='pr-4 mt-2'>{editQues?.subtxt}</p>
                            }

                            {
                                editQues?.hasOwnProperty('options') &&
                                <div className='row align-items-center'>
                                    {
                                        editQues?.options?.map(opt =>
                                            <div className='col-lg-4 col-sm-6 col-12' onClick={() => handleUpdate(editQues, opt?.value)} data-toggle="collapse" data-target={`#collapseEdit${index}`} aria-expanded="false" aria-controls={`collapseEdit${index}`}>
                                                <QuestionOption value={opt?.value} icon={opt?.icon} currentValue={editQues?.value} />
                                            </div>
                                        )
                                    }
                                </div>
                            }

                            {
                                editQues?.input &&
                                <>
                                    {
                                        editQues?.money ?
                                            <div className={`input-icon ${screenWidth < 576 ? 'w-100' : 'w-50'}`}>
                                                <input id='numInput' onChange={handleNumberInput} onBlur={removeInput} type="number" min='0' className='form-control input-field' />
                                                <i>$</i>
                                            </div>
                                            :
                                            <div className={`input-icon ${screenWidth < 576 ? 'w-100' : 'w-50'}`}>
                                                <input id='textInput' onChange={handleAddressInput} onBlur={removeInput} type="text" className='form-control input-field' />
                                            </div>
                                    }


                                    {
                                        questionDatabase?.length === index ?
                                       
                                            // inputVal !== "" ?
                                            //     <button onClick={() => handleSubmit(editQues, inputVal)} className="main-button btn mt-4 d-block ml-auto">Submit</button>
                                            //     :
                                                <button className="main-button btn mt-4 d-block ml-auto disabled">Submit</button>
                                            :
                                            <></>
                                    }

                                    {
                                        questionDatabase?.length !== index ?
                                            inputVal !== "" ?
                                                <div data-toggle="collapse" data-target={`#collapseEdit${index}`} aria-expanded="true" aria-controls={`collapseEdit${index}`}>
                                                    <button onClick={() => handleUpdate(editQues, inputVal)} className="main-button btn mt-4 d-block ml-auto" >Next</button>
                                                </div>
                                                :
                                                <button className="main-button btn mt-4 d-block ml-auto disabled">Next</button>
                                            :
                                            <></>
                                    }
                                </>
                            }
                            {
                                editQues?.fileInput &&
                                <>
                                    <label for="file-upload" className="d-block">
                                        <div className='px-0 input-container'>
                                            <span className='font-weight-bold d-block text-center h5'>{img?.name ? img?.name : 'Upload'}</span>
                                        </div>
                                    </label>

                                    <input id="file-upload" type="file" onChange={handleImageUpload} />

                                    <div className='d-flex justify-content-between align-items-center'>
                                        {
                                            img?.name ?
                                                <div data-toggle="collapse" data-target={`#collapseEdit${index}`} aria-expanded="true" aria-controls={`collapseEdit${index}`}>
                                                    <button onClick={() => handleImageUpdate(editQues)} className="main-button btn mt-4 d-block ml-auto">Next</button>
                                                </div>
                                                :
                                                <button className="main-button btn mt-4 d-block ml-auto disabled">Next</button>
                                        }
                                    </div>
                                </>
                            }

                        </div>
                    }
                </div>


            </div>
        </div>
    )
}

export default AnsweredQuestionCard
