/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { useContext, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { UserContext } from '../contextApi/AppContext'
import './QuestionCard.css'
import QuestionOption from './QuestionOption'
import emailjs from "emailjs-com";

function QuestionCard({ img, ques, handleAnswer, index, handleNumberInput, inputVal, handleAddressInput, handleImageUpload, handleImageSubmit, removeInput, setShowModal }) {
    const { questionDatabase, userInfo, allQuestion, dealDate, setAllQuestion, setIsUpdated } = useContext(UserContext)
    const screenWidth = window.innerWidth
    const location = useLocation()

    const handleSubmit = (ques, inputVal, lastQues) => {
        if (allQuestion.find(data => data?.ques === ques?.ques)?.hasOwnProperty('ques')) {
            setAllQuestion([...allQuestion])
        }
        else {
            setAllQuestion([...allQuestion, { ques: ques?.ques, value: inputVal }])
        }

        fetch(`https://salura-capital-backend.herokuapp.com/updateLeads?_id=${location?.pathname?.split('/')[2]}`, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ userInfo, allQuestion: allQuestion?.find(data => data?.ques === ques?.ques)?.hasOwnProperty('ques') ? [...allQuestion] : [...allQuestion, { ques: ques?.ques, value: inputVal }], date: dealDate })
        })
            .then(res => {
                if (res) {
                    setIsUpdated(true)
                    sessionStorage.setItem('allData', JSON.stringify({ userInfo, allQuestion: allQuestion?.find(data => data?.ques === ques?.ques)?.hasOwnProperty('ques') ? [...allQuestion] : [...allQuestion, { ques: ques?.ques, value: inputVal }], date: dealDate }))

                    if (lastQues) {
                        emailjs.send('service_pb1j4qb', 'template_y5yrz3e', { firstName: userInfo?.firstName, email: userInfo?.email }, "user_4HDeWArixokfADpUx1p9P")
                            .then((result) => {
                               setShowModal(true)
                            }, (error) => {
                                console.log(error.text);
                            });
                    }

                }
            })
    }


    return (
        <section className='py-4 container' id="accordion">
            <div className='question my-2 py-4 row justify-content-between Card'>
                <div className='col-1 mt-2'>
                    <p className='circle'>{index}</p>
                </div>
                <div className='col-11' >
                    <h2 className='pointer' data-toggle="collapse" data-target={`#collapse${index}`} aria-expanded="true" aria-controls={`collapse${index}`}>{ques?.ques}</h2>

                    <div className='collapse show' id={`collapse${index}`} aria-labelledby="headingOne" data-parent="#accordion">
                        {
                            ques?.hasOwnProperty('subtxt') &&
                            <p className='pr-4'>{ques?.subtxt}</p>
                        }

                        {
                            ques?.hasOwnProperty('options') &&
                            <div className='row align-items-center'>
                                {
                                    ques?.options?.map(opt =>
                                        <div className='col-lg-4 col-sm-6 col-12' onClick={() => handleAnswer(ques, opt?.value)}>
                                            <QuestionOption currentValue={allQuestion[index - 1]?.value} value={opt?.value} icon={opt?.icon} />
                                        </div>
                                    )
                                }
                            </div>
                        }
                        {
                            ques?.skip && !ques?.fileInput && !ques?.input &&
                            <div className='d-flex align-items-center jusitfy-content-start'>
                                <p onClick={() => handleAnswer(ques, 'Skipped')} className='pointer skip'>Skip Question</p>
                            </div>

                        }

                        {
                            questionDatabase?.length === index && !ques?.fileInput ?
                                allQuestion[index - 1]?.value ?
                                    <button onClick={() => handleSubmit(ques, inputVal, 'true')} className="main-button btn mt-4 d-block ml-auto" data-toggle="modal" data-target="#exampleModal">Submit Deal</button>
                                    :
                                    <button className="main-button btn mt-4 d-block ml-auto disabled">Submit</button>
                                :
                                <></>
                        }


                        {
                            ques?.input &&
                            <>
                                {
                                    ques?.money ?
                                        <div className={`input-icon ${screenWidth < 576 ? 'w-100' : 'w-50'}`}>
                                            <input value={inputVal} id='numInput' onBlur={removeInput} onChange={handleNumberInput} min='0' className='form-control input-field' />
                                            <i>$</i>
                                        </div>
                                        :
                                        <div className={`input-icon ${screenWidth < 576 ? 'w-100' : 'w-50'}`}>
                                            <input id='textInput' onBlur={removeInput} onChange={handleAddressInput} type="text" className='form-control input-field' />
                                        </div>
                                }


                                {
                                    questionDatabase?.length !== index ?
                                        <div className='d-flex justify-content-between align-items-center'>
                                            {
                                                ques?.skip &&
                                                <p onClick={() => handleAnswer(ques, 'Skipped')} className='pt-3 mb-0 pointer skip'>Skip Question</p>
                                            }
                                            {
                                                inputVal !== "" ?
                                                    <button onClick={() => handleAnswer(ques, inputVal)} className="main-button btn mt-4 d-block ml-auto">Next</button>
                                                    :
                                                    <button className="main-button btn mt-4 d-block ml-auto disabled">Next</button>
                                            }
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                        }

                        {
                            ques?.fileInput &&
                            <>
                                <label for="file-upload" className="d-block">
                                    <div className='px-0 input-container'>
                                        <span className='font-weight-bold d-block text-center h5'>{img?.name ? img?.name : 'Upload'}</span>
                                    </div>
                                </label>

                                <input id="file-upload" type="file" onChange={handleImageUpload} />

                                <div className='d-flex justify-content-between align-items-center'>
                                    {
                                        !img?.name && <p onClick={() => handleAnswer(ques, 'Skipped')} className='pt-3 mb-0 pointer skip'>Skip Question</p>
                                    }


                                    {
                                        questionDatabase?.length === index ?
                                            img?.name ?
                                                <button onClick={() => handleImageSubmit(ques)} className="main-button btn mt-4 d-block ml-auto">Submit Deal</button>
                                                : allQuestion[index - 1]?.value === 'Skipped' ?
                                                    <button onClick={() => handleSubmit(ques, 'Skipped', 'true')} className="main-button btn mt-4 d-block ml-auto" data-toggle="modal" data-target="#exampleModal">Submit Deal</button>
                                                    :
                                                    <button className="main-button btn mt-4 d-block ml-auto disabled">Submit</button>
                                            : <></>
                                    }
                                    {
                                        questionDatabase?.length !== index ?
                                            img?.name ?
                                                <button onClick={() => handleImageSubmit(ques)} className="main-button btn mt-4 d-block ml-auto">Next</button>
                                                :
                                                <button className="main-button btn mt-4 d-block ml-auto disabled">Next</button>
                                            : <></>
                                    }
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default QuestionCard
