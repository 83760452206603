/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { UserContext } from '../contextApi/AppContext';
import './DashboardNav.css';
import logo from '../assets/logo.png'

function DashboardNav() {
    const history = useHistory()
    const { userInfo, setAllQuestion, setAnsweredQues, setUnAnsweredQues, setQuestionDatabase, setUserInfo, setLoanType, setAllData } = useContext(UserContext)

    const handleLogout = () => {
        sessionStorage.removeItem('database')
        sessionStorage.removeItem('allData')
        setAllQuestion([])
        setAnsweredQues(0)
        setUnAnsweredQues(0)
        setQuestionDatabase([])
        setUserInfo({})
        setLoanType("")
        setAllData([])
        history.replace('/')
    }
    return (
        <div className='dark-nav'>
                <nav className="navbar navbar-expand container">
                    <a className="navbar-brand text-white py-0">
                        <img className='w-100' src={logo} alt='salura logo'/>
                    </a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse text-white" id="navbarSupportedContent">
                        <p className="nav-item dropdown ml-auto mb-0">
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fas fa-user-alt nav-icon mr-2"></i>
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <div className='d-flex align-items-center dropdown-item my-2'>
                                    <i className="fas fa-user-alt dp-icon text-secondary mr-3"></i>
                                    <div>
                                        <h6 className='mb-0'>{userInfo?.firstName} {userInfo?.lastName}</h6>
                                        <small className='text-secondary'>{userInfo?.email}</small>
                                    </div>
                                </div>
                                <Link className="dropdown-item" to="/dashboard">Dashboard</Link>
                                <Link className="dropdown-item" to="/profile">Profile</Link>
                                <Link onClick={() => handleLogout()} className="dropdown-item">Logout</Link>
                            </div>
                        </p>


                    </div>
                </nav>
        </div>
    )
}

export default DashboardNav