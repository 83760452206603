import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../contextApi/AppContext";
import "./LoanCard.css";

function LoanCard({ data }) {
    const { loanType } = useContext(UserContext)
    const icon = loanType === 'Business' ? 'fas fa-business-time' : 'far fa-building';
    const [totalQues, setTotalQues] = useState(0)
    const [documentLen, setDocumentLen] = useState(0)


    useEffect(() => {
        if (data?.allQuestion[0]?.value === 'Multi-family') {
            if (data?.allQuestion[1]?.value === 'Acquisition') {
                if (data?.allQuestion[4]?.value === 'Yes') {
                    setTotalQues(15)
                    setDocumentLen(6)
                }
                else {
                    setTotalQues(14)
                    setDocumentLen(6)
                }
            }
            else if (data?.allQuestion[1]?.value === 'Refinance') {
                setTotalQues(11)
                setDocumentLen(4)
            }
            else {
                setTotalQues(10)
                setDocumentLen(4)
            }
        }
        else {
            if (data?.allQuestion[1]?.value === 'Acquisition') {
                setTotalQues(15)
                setDocumentLen(6)
            }
            else if (data?.allQuestion[1]?.value === 'Refinance') {
                setTotalQues(12)
                setDocumentLen(4)
            }
            else {
                setTotalQues(11)
                setDocumentLen(4)
            }
        }

    }, [data?.allQuestion])

    return (
        <div className="col-sm-6 col-12 col-lg-4 my-3">
            <div className="card loan">
                <div className="card-body loan-body">
                    <div className="d-flex mb-3 align-items-center">
                        <i className={`${icon} mr-4 icon`}></i>
                        <p className="text-left text-whtie mb-0">
                            <p className='small mb-0'>{data?.date}</p>
                            <span className='h5'>${data?.allQuestion[0]?.value === 'Multi-family' ? `${parseInt(data?.allQuestion[2].value.split(" ")[1].replace(/,/g, ''), 10) / 1000}K ${data?.allQuestion[0]?.value} ${data?.allQuestion[1]?.value} Loan` : `${parseInt(data?.allQuestion[3].value.split(" ")[1].replace(/,/g, ''), 10) / 1000}K ${data?.allQuestion[0]?.value} ${data?.allQuestion[2]?.value} Loan`}</span>
                        </p>
                    </div>

                    <div className="progress">
                        <div
                            className="progress-bar"
                            style={{ width: `${(data?.allQuestion?.length - data?.allQuestion?.filter(ques => ques?.value === 'Skipped')?.length) / (data?.allQuestion[0]?.value === 'Business' ? 13 : 37) * 100}%` }}
                            role="progressbar"
                            aria-valuenow="25"
                            aria-valuemin="0"
                            aria-valuemax="100"
                        ></div>
                    </div>


                    <div className="row">
                        <div className="col-8">
                            <div className="d-flex mt-3">
                                <i className="fas fa-comment-alt mr-3 pt-1"></i>
                                <p className="text-left text-white">Question Answered</p>
                            </div>
                        </div>
                        <div className="col-4">
                            <p className="mt-3">{data?.allQuestion?.length - data?.allQuestion?.filter(ques => ques?.value === 'Skipped')?.length}/<span className="text-white">{totalQues}</span></p>
                        </div>

                        <div className="col-8">
                            <div className="d-flex">
                                <i className="fas fa-file mr-3 pt-1"></i>
                                <p className="text-left text-white">Document Uploaded</p>
                            </div>
                        </div>
                        <div className="col-4">
                            <p className="">{data?.allQuestion.filter(ques => ques?.isUploaded === 'Uploaded')?.length}/<span className="text-white">{documentLen}</span></p>
                        </div>
                    </div>
                    <div className="text-right border-line">
                        {/* <button className="btn card-button-one mr-4">
                                    Archive
                                </button> */}
                        <Link to={`/myDeal/${data?._id}`}>
                            <button className="btn card-button-two">
                                Edit
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoanCard;