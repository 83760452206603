import React from "react";
import "./HomePage.css";
import logo from "../../assets/logo.png";
import { Link, useHistory } from 'react-router-dom'



const HomePage = () => {
  const history = useHistory()
  const screenWidth = window.innerWidth

  const handleRedirect = () => {
    sessionStorage.setItem('authCode', JSON.stringify(`${Math.floor(Math.random() * 90000) + 10000}`))
    history.push('/sign-up')
  }

  return (
    <section className='bg-img'>
      <div className="row">
        <div className="col-lg-4 left-sidebar">
          <div className="sidebar d-flex flex-column justify-content-between  " >
            <img src={logo} className='logo w-35 my-2 mx-5' alt='logo' />
          </div>
        </div>

        <div className="col-lg-7">
          <div className="d-flex align-items-center mx-2 mx-md-5 h-100">
            <div className='w-100'>
              <h1 className="banner-title text-darkBlue">
                Get commercial property
                <br /> financing<span className="text-lightBlue"> your bank can't
                  <br /> compete</span> with.
              </h1>
              <p className="banner-dscription mt-4 mb-4">
                We're making commercial property financing easier.<br /> Click the
                button below to get started.
              </p>
              <button onClick={() => handleRedirect()} className="main-button">Get Started</button>
              <div className='border-top mt-5 d-flex justify-content-between align-items-center'>
                <p className='mb-0 mt-3 small'>Copyright {new Date().getFullYear()} &copy; Salura Capital</p>
                <Link to='/login' className='mt-3'>
                  <button className="btn login-btn">Login</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomePage;