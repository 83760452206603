import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import logo from '../../assets/logo.png'
import { UserContext } from '../../contextApi/AppContext'

function Login() {
    const { setAllData, setUserInfo } = useContext(UserContext)
    const [email, setEmail] = useState('')
    const [notRegistered, setNotRegisterd] = useState(false)
    const history = useHistory()
    const screenWidth = window.innerWidth

    const handleNumberInput = e => {
        setEmail(e.target.value)
    }

    const handleSubmit = () => {
        fetch(`https://salura-capital-backend.herokuapp.com/allLeads?email=${email}`)
            .then(res => res.json())
            .then(data => {
                if (data?.length) {
                    sessionStorage.setItem('database', JSON.stringify(data))
                    setAllData(data)
                    setUserInfo(data[0]?.userInfo)
                    setEmail("")
                    history.replace('/dashboard')
                }
                else {
                    setNotRegisterd(true)
                }
            })
    }

    return (
        <section className='bg-img'>
            <div className="row">
                <div className="col-lg-4 left-sidebar">
                    <div className="sidebar d-flex flex-column justify-content-between  " >
                        <img src={logo} className='logo w-35 my-2 mx-5' alt='logo' />
                    </div>
                </div>

                <div className="col-lg-7">
                    <div className="d-flex align-items-center mx-2 mx-md-5 h-100">
                        <div className='w-100'>
                            <h1 className="banner-title">
                                Welcome back to
                                <br /><span className="banner-color"> Salura Capital</span>
                            </h1>

                            {
                                notRegistered ?
                                    <h2 className='text-danger my-5'>Your email is not regestered yet!</h2>
                                    :
                                    <>
                                        <p className="my-4 h5">
                                            Enter you email to login
                                        </p>
                                        <div className={`input-icon ${screenWidth < 576 ? 'w-100' : 'w-75'}`}>
                                            <input onChange={handleNumberInput} type="email" placeholder='john@creprops.com' className='form-control input-field' />
                                            {
                                                email !== '' ?
                                                    <button onClick={() => handleSubmit()} className='main-button mt-3'>Login</button>
                                                    :
                                                    <button className='main-button mt-3 disabled'>Login</button>
                                            }
                                        </div>
                                    </>
                            }

                            <div className='border-top mt-5 d-flex justify-content-between align-items-center'>
                                <p className='mb-0 mt-3 small text-md-left text-center'>Copyright {new Date().getFullYear()} &copy; Salura Capital</p>
                                <Link to='/sign-up' className='mt-3'>
                                    <button className="main-button">Get Start</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Login
