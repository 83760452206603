import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router';
import { UserContext } from '../../contextApi/AppContext';


function VerificationCenter() {
    const { userInfo, allQuestion, setAllData, authCode, setAuthCode } = useContext(UserContext)
    const history = useHistory()
    const [code, setCode] = useState(null)

    const handleSubmit = () => {
        if (code === authCode) {
            fetch('https://salura-capital-backend.herokuapp.com/postLeads', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ userInfo, allQuestion, date: new Date().toDateString() })
            })
                .then(res => {
                    if (res) {
                        fetch(`https://salura-capital-backend.herokuapp.com/allLeads?email=${userInfo?.email}`)
                            .then(res => res.json())
                            .then(resData => {
                                setAllData(resData)
                                sessionStorage.setItem('database', JSON.stringify(resData))
                                setAuthCode(null)
                                sessionStorage.removeItem('authCode')
                                sessionStorage.setItem('allData', JSON.stringify(resData[resData?.length - 1]))
                                history.replace(`/myDeal/${resData[resData?.length - 1]?._id}`)
                            })
                    }
                })
        }
        else{
            alert("Auth Code doesn't match. Please provide the right auth code.")
        }
    }

    const handleNumberInput = e => {
        setCode(e.target.value)
    }

    return (
        <div className='bg-cream h-100 '>
            <div className='container w-75 d-block mx-auto'>
                <h2 className='text-center pt-5'>We have sent you a verification mail. Please verify your account to step further. Enter the code to verify you email and create your account</h2>

                <div className='input-icon w-75 d-block mx-auto'>
                    <input onChange={handleNumberInput} type="text" className='form-control input-field w-75 d-block mx-auto' />

                    <button onClick={handleSubmit} className='btn main-button d-block mx-auto my-4'>Submit</button>
                </div>
            </div>
        </div>
    )
}

export default VerificationCenter
