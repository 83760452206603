import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import './App.css';
import AppContext from './contextApi/AppContext';
import Dashboard from "./pages/Dashboard/Dashboard";
import DashBoardQuestion from "./pages/Dashboard/DashBoardQuestion";
import DownloadExcel from "./pages/DownloadExcel";
import HomePage from "./pages/Home/HomePage";
import Profile from "./pages/Profile/Profile";
import SignUp from "./pages/SignUp/SignUp";
import Login from "./pages/VerificationCenter/Login";
import VerificationCenter from "./pages/VerificationCenter/VerificationCenter";

function App() {
  return (
    <AppContext>
      <Router>
        <Switch>
          <Route exact path="/home">
            <HomePage />
          </Route>
          <Route path="/dashboard">
            <Dashboard />
          </Route>
          <Route path="/profile">
            <Profile />
          </Route>
          <Route exact path='/sign-up'>
            <SignUp />
          </Route>
          <Route exact path='/login'>
            <Login />
          </Route>
          <Route exact path='/myDeal/:_id'>
            <DashBoardQuestion />
          </Route>
          <Route exact path='/verification-center'>
            <VerificationCenter />
          </Route>
          <Route exact path="/downloadData">
            <DownloadExcel />
          </Route>
          <Route exact path="/">
            <HomePage />
          </Route>
        </Switch>
      </Router>
    </AppContext>
  );
}

export default App;
