import React, { useContext } from "react";
import { Link } from "react-router-dom";
import DashboardNav from "../../components/DashboardNav";
import LoanCard from "../../components/LoanCard";
import { UserContext } from "../../contextApi/AppContext";
import "./Dashboard.css";

function Dashboard() {
    const { allData, userInfo } = useContext(UserContext)

    return (

        <div className="dashboard-header">
            <DashboardNav />
            <div className="mx-md-5">
                <div className="container">
                    <h1 className="dashboard-title mb-4">
                        Welcome to your loan dashboard, {allData[0]?.userInfo?.firstName}!
                    </h1>
                    <p className="dashboard-subtitle">
                        You can process any type of multifamily, commercial property or
                        small business loan through Salura Capital, right here! Below you
                        will see open deals you are working on, that you can edit in real
                        time. Once you submit a loan; our system will quickly and
                        automatically work to match you with the right lender. Good luck!<br /><br />
                        Got some feedback or constructive criticism? Click here to tell us
                        how we can do better!
                    </p>
                </div>

                {/* section two */}

                <div className="container-fluid dashboard-card px-0 px-md-3">
                    <div className="numbers d-flex flex-md-row flex-wrap justify-content-center align-items-center">
                        <div className="rect my-5 container">
                            <div className="mx-4 mb-5 d-flex flex-column flex-md-row justify-content-between">
                                <h3 className="mt-2 text-center text-md-left">Loan Request <span className="badge badge-primary">{allData?.length}</span></h3>
                                <Link to='/sign-up'>
                                    <button className='main-button d-block mx-auto mt-3 mt-md-0'>New Loan Request</button>
                                    </Link>
                            </div>
                            <div className='row'>
                                {
                                    allData?.length &&
                                    allData?.map(data =>
                                        <LoanCard data={data} />
                                    )
                                }
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    );
}

export default Dashboard;