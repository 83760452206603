export const propertyQuestion = [
    {
        ques: 'What type of property are you inquiring about?',
        subtxt: 'If the property is a mix of multifamily and other uses such as office or retail, please select "Multifamily" as your property type.',
        options: [
            {
                icon: 'far fa-building',
                value: 'Multi-family',
            },
            {
                icon: 'fas fa-briefcase',
                value: 'Office',
            },
            {
                icon: 'fas fa-industry',
                value: 'Industrial / Warehouse',
            },
            {
                icon: 'fas fa-store',
                value: 'Retail',
            },
            {
                icon: 'fas fa-ellipsis-h',
                value: 'Other',
            },
        ]
    },
    {
        ques: 'Will the property be Owner Occupied?',
        subtxt: 'If more than 51% of the rentable SF will be owner occupied, please select Owner Occupied.',
        options: [
            {
                value: 'Owner Occupied',
            },
            {
                value: 'No',
            },
        ]
    },
    {
        ques: 'What is the primary purpose of this loan?',
        options: [
            {
                value: 'Acquisition',
            },
            {
                value: 'Refinance',
            },
            {
                value: 'Construction',
            },
        ]
    },
    {
        ques: 'What is the requested loan amount?',
        subtxt: "If you aren't entirely sure, please make your best educated guess.",
        input: true,
        money: true
    },
    {
        ques: 'Contact Details',
        subtxt: "Please share your contact information with us so we can provide financing options and a quote. We respect your privacy.",
        smallTxt: 'By clicking Submit, I confirm that I have read and consent to the Terms of Use, Privacy Policy and receiving calls, including via cell phone, and messages, including emails, SMS, autodialed or pre-recorded calls for marketing purposes, from Salura Capital and its partners using the information provided above and that I may opt out any time.',
        isContact: true
    },
]