/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useEffect, useState } from 'react'
import { BusinessNotContract } from '../Database/Industrial/Acquisition/NotContract'
import { BusinessUnderContract } from '../Database/Industrial/Acquisition/UnderContract'
import { BusinessConstruction } from '../Database/Industrial/Construction'
import { BusinessRefinance } from '../Database/Industrial/Refinance'
import { FamilyNotContract } from '../Database/Multifamily/Acquistion/NotContract'
import { FamilyUnderContract } from '../Database/Multifamily/Acquistion/UnderContract'
import { FamilyConstruction } from '../Database/Multifamily/Construction'
import { FamilyRefinance } from '../Database/Multifamily/Refinance'

export const UserContext = createContext()

function AppContext(props) {
    const [loanType, setLoanType] = useState(JSON.parse(sessionStorage.getItem('allData'))?.allQuestion?.length ? JSON.parse(sessionStorage.getItem('allData'))?.allQuestion[0]?.value : "")
    const [conditionals, setConditionals] = useState(JSON.parse(sessionStorage.getItem('conditionals'))?.type ? JSON.parse(sessionStorage.getItem('conditionals')) : {})
    const [userInfo, setUserInfo] = useState(JSON.parse(sessionStorage.getItem('allData'))?.userInfo || {})
    const [allQuestion, setAllQuestion] = useState(JSON.parse(sessionStorage.getItem('allData'))?.allQuestion?.length ? JSON.parse(sessionStorage.getItem('allData'))?.allQuestion : [])
    const [questionDatabase, setQuestionDatabase] = useState([])
    const [answeredQues, setAnsweredQues] = useState(JSON.parse(sessionStorage.getItem('allData'))?.allQuestion?.length || 0)
    const [unAnsweredQuestion, setUnAnsweredQues] = useState(0)
    const [allData, setAllData] = useState(JSON.parse(sessionStorage.getItem('database')) || [])
    const [dealDate, setDealDate] = useState(JSON.parse(sessionStorage.getItem('allData'))?.date)
    const [authCode, setAuthCode] = useState(JSON.parse(sessionStorage.getItem('authCode')) || '')
    const [isUpdated, setIsUpdated] = useState(false)


    useEffect(() => {
        if (loanType === 'Multi-family' || allQuestion[0]?.value === 'Multi-family') {
            if (conditionals?.type === 'Acquisition' || allQuestion[1]?.value === 'Acquisition') {
                if (conditionals?.isContract === 'Yes' || allQuestion[4]?.value === 'Yes') {
                    setQuestionDatabase(FamilyUnderContract)
                }
                else {
                    setQuestionDatabase(FamilyNotContract)
                }
            }
            else if (conditionals?.type === 'Refinance' || allQuestion[1]?.value === 'Refinance') {
                setQuestionDatabase(FamilyRefinance)
            }
            else {
                setQuestionDatabase(FamilyConstruction)
            }

        }
        else if (loanType === 'Office' || loanType === 'Industiral / Warehouse' || loanType === 'Retail' || loanType === 'Other' || allQuestion[0]?.value === 'Multi-family') {
            if (conditionals?.type === 'Acquisition' || allQuestion[2]?.value === 'Acquisition') {
                if (conditionals?.isContract === 'Yes' || allQuestion[5]?.value === 'Yes') {
                    setQuestionDatabase(BusinessUnderContract)
                }
                else {
                    setQuestionDatabase(BusinessNotContract)
                }
            }
            else if (conditionals?.type === 'Refinance' || allQuestion[2]?.value === 'Refinance') {
                setQuestionDatabase(BusinessRefinance)
            }
            else {
                setQuestionDatabase(BusinessConstruction)
            }
        }
    }, [loanType, conditionals?.type, conditionals?.isContract, allQuestion])


    useEffect(() => {
        setAuthCode(JSON.parse(sessionStorage.getItem('authCode')))
    }, [JSON.parse(sessionStorage.getItem('authCode'))?.length])

    useEffect(() => {
        fetch(`https://salura-capital-backend.herokuapp.com/allLeads?email=${userInfo?.email}`)
            .then(res => res.json())
            .then(data => {
                sessionStorage.setItem('database', JSON.stringify(data))
                setAllData(data)
            })
    }, [isUpdated, userInfo?.email])


    useEffect(() => {
        if (JSON.parse(sessionStorage.getItem('allData'))?.allQuestion?.length) {
            setAllQuestion(JSON.parse(sessionStorage.getItem('allData'))?.allQuestion)
            setUserInfo(JSON.parse(sessionStorage.getItem('allData'))?.userInfo)
            setDealDate(JSON.parse(sessionStorage.getItem('allData'))?.date)
            setUnAnsweredQues(JSON.parse(sessionStorage.getItem('allData'))?.allQuestion?.filter(ques => ques?.value === 'Skipped'))
            setAnsweredQues(JSON.parse(sessionStorage.getItem('allData'))?.allQuestion?.length)
        }

    }, [JSON.parse(sessionStorage.getItem('allData'))?.allQuestion?.length])

    useEffect(() => {
        setConditionals(JSON.parse(sessionStorage.getItem('conditionals')) || {})
    }, [conditionals?.type, conditionals?.isContract])

    return (
        <UserContext.Provider
            value={{
                loanType,
                setLoanType,
                userInfo,
                setUserInfo,
                allQuestion,
                setAllQuestion,
                questionDatabase,
                setQuestionDatabase,
                answeredQues,
                setAnsweredQues,
                unAnsweredQuestion,
                setUnAnsweredQues,
                dealDate,
                setDealDate,
                allData,
                setAllData,
                authCode,
                setAuthCode,
                isUpdated,
                setIsUpdated,
                conditionals,
                setConditionals
            }}
        >
            {props.children}
        </UserContext.Provider>
    )
}

export default AppContext
