import React, { useContext } from 'react'
import { useHistory } from 'react-router'
import { UserContext } from '../contextApi/AppContext'
import emailjs from "emailjs-com";


function ContactDetails({ ques, index }) {
    const { userInfo, setUserInfo, authCode, allQuestion, setAllData } = useContext(UserContext)
    const history = useHistory()

    const handleChange = e => {
        const newUpdate = { ...userInfo }
        newUpdate[e.target.name] = e.target.value;
        setUserInfo(newUpdate)
    }

    const handleVerification = (e) => {
        emailjs.send('service_pb1j4qb', 'template_g3g9o4u', { firstName: userInfo?.firstName, email: userInfo?.email, code: authCode }, "user_4HDeWArixokfADpUx1p9P")
            .then((result) => {
                sessionStorage.setItem('allData', JSON.stringify({ userInfo, allQuestion, date: new Date().toDateString() }))
                history.replace('/verification-center')
            }, (error) => {
                console.log(error.text);
            });
    }

    return (
        <div className='container'>
            <div className='question my-4 py-4 row justify-content-between Card'>
                <div className='col-1 mt-2'>
                    <p className='circle'>{index}</p>
                </div>
                <div className='col-11'>
                    <h2>{ques?.ques}</h2>
                    {
                        ques?.hasOwnProperty('subtxt') &&
                        <p className='pr-4'>{ques?.subtxt}</p>
                    }

                    <form >
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label for="firstName">First Name</label>
                                <input onChange={handleChange} type="text" className="form-control input-field" name='firstName' id="firstName" placeholder="John" required />
                            </div>
                            <div className="form-group col-md-6">
                                <label for="lastName">Last Name</label>
                                <input onChange={handleChange} type="text" className="form-control input-field" name='lastName' id="lastName" placeholder="Corner" required />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label for="phone">Phone Number</label>
                                <input onChange={handleChange} type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" name='tel' className="form-control input-field" id="phone" placeholder="" required />
                            </div>
                            <div className="form-group col-md-6">
                                <label for="email">Email Address</label>
                                <input onChange={handleChange} type="email" className="form-control input-field" name='email' id="email" placeholder="me@company.com" required />
                            </div>
                        </div>
                    </form>


                    {
                        (userInfo?.firstName && userInfo?.lastName && userInfo?.tel && userInfo?.email) ?
                            <button onClick={() => handleVerification()} className="main-button btn mt-4 d-block ml-auto">Submit</button>


                            :
                            <button className="main-button btn mt-4 d-block ml-auto disabled">Submit</button>
                    }

                </div>
            </div>
        </div>
    )
}

export default ContactDetails
