/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router";
import DashboardNav from "../../components/DashboardNav";
import { UserContext } from "../../contextApi/AppContext";
import "./Profile.css";

const Profile = () => {
    const { userInfo, setUserInfo } = useContext(UserContext)
    const history = useHistory()

    const handleChange = e => {
        const updatedInfo = { ...userInfo }
        updatedInfo[e.target.name] = e.target.value
        setUserInfo(updatedInfo)
    }

    useEffect(() => {
        document.getElementById('firstName').value = userInfo?.firstName || ""
        document.getElementById('lastName').value = userInfo?.lastName || ""
        document.getElementById('tel').value = userInfo?.tel || ""
        document.getElementById('email').value = userInfo?.email || ""
        document.getElementById('birthDate').value = userInfo?.birthDate || ""
        document.getElementById('businessStreet').value = userInfo?.businessStreet || ""
        document.getElementById('businessBuilding').value = userInfo?.businessBuilding || ""
        document.getElementById('businessZip').value = userInfo?.businessZip || ""
        document.getElementById('businessCity').value = userInfo?.businessCity || ""
        document.getElementById('homeStreet').value = userInfo?.homeStreet || ""
        document.getElementById('homeBuilding').value = userInfo?.homeBuilding || ""
        document.getElementById('homeZip').value = userInfo?.homeZip || ""
        document.getElementById('homeCity').value = userInfo?.homeCity || ""
    }, [])


    const handleDatabaseUpdate = () => {
        fetch(`https://salura-capital-backend.herokuapp.com/updateUser?email=${userInfo?.email}`, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(userInfo)
        })
            .then(res => res.json())
            .then(data => {
                if (data) {
                    history.replace('/dashboard')
                }
            })
    }

    return (
        <>
            <div className="profile-section pb-5 bg-cream">
                <DashboardNav />
                <h2 className="text-center mt-4 profile-title">Your Profile</h2>
                <div className="profile  mt-5">
                    {/* Persolan info */}

                    <div className="row justify-content-center mt-5">
                        <div className="col-md-6 ">
                            <div className="card profile-card">
                                <div className="card-body">
                                    <h5 className="card-title">Personal Info</h5>
                                    <div className="personal-info mt-4">
                                        <table className='w-100'>
                                            <tr className="table-form">
                                                <td>First Name</td>
                                                <td>
                                                    <input
                                                        className='px-3'
                                                        onChange={handleChange}
                                                        type="text"
                                                        name="firstName"
                                                        id='firstName'
                                                        size="30"
                                                        maxlength="30"
                                                        placeholder="Enter first name"
                                                    />
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>Last Name</td>
                                                <td className="ml-5">
                                                    <input
                                                        className='px-3'
                                                        onChange={handleChange}
                                                        type="text"
                                                        name="lastName"
                                                        id='lastName'
                                                        size="30"
                                                        maxlength="30"
                                                        placeholder="Enter last name"
                                                    />
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>Phone Number</td>
                                                <td className="ml-5">
                                                    <input
                                                        className='px-3'
                                                        onChange={handleChange}
                                                        type="number"
                                                        name="tel"
                                                        id="tel"
                                                        size="30"
                                                        maxlength="30"
                                                        placeholder="Enter phone number"
                                                    />
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>Your Email</td>
                                                <td className="ml-5">
                                                    <input
                                                        className='px-3'
                                                        type="email"
                                                        name="email"
                                                        id="email"
                                                        readOnly
                                                    />
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* date of birth */}

                    <div className="row justify-content-center mt-5">
                        <div className="col-md-6 ">
                            <div className="card profile-card">
                                <div className="card-body">
                                    <h5 className="card-title">Date of Birth</h5>
                                    <div className="birth-date mt-4">
                                        <input
                                            onChange={handleChange}
                                            type="date"
                                            className="form-control"
                                            id="birthDate"
                                            name='birthDate'
                                            placeholder="Date of Birth"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Business Adress */}

                    <div className="row justify-content-center mt-5">
                        <div className="col-md-6 ">
                            <div className="card profile-card">
                                <div className="card-body">
                                    <h5 className="card-title">Business address</h5>
                                    <div className="personal-info mt-4">
                                        <table className='w-100'>
                                            <tr className="table-form">
                                                <td>Street</td>
                                                <td>
                                                    <input
                                                        className='px-3'
                                                        onChange={handleChange}
                                                        type="text"
                                                        name="businessStreet"
                                                        id='businessStreet'
                                                        size="30"
                                                        maxlength="30"
                                                        placeholder="5th Avenue"
                                                    />
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>Building</td>
                                                <td className="ml-5">
                                                    <input
                                                        className='px-3'
                                                        onChange={handleChange}
                                                        type="text"
                                                        name="businessBuilding"
                                                        id='businessBuilding'
                                                        size="30"
                                                        maxlength="30"
                                                        placeholder="Suite G-111"
                                                    />
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>Zip</td>
                                                <td className="ml-5">
                                                    <input
                                                        className='px-3'
                                                        onChange={handleChange}
                                                        type="number"
                                                        name="businessZip"
                                                        id='businessZip'
                                                        size="30"
                                                        maxlength="30"
                                                        placeholder="12345"
                                                    />
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>City,State</td>
                                                <td className="ml-5">
                                                    <input
                                                        className='px-3'
                                                        onChange={handleChange}
                                                        type="text"
                                                        name="businessCity"
                                                        id='businessCity'
                                                        size="30"
                                                        maxlength="30"
                                                        placeholder="New York, NY"
                                                    />
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Home Address */}

                    <div className="row justify-content-center mt-5">
                        <div className="col-md-6 ">
                            <div className="card profile-card">
                                <div className="card-body">
                                    <h5 className="card-title">Home address</h5>
                                    <div className="personal-info mt-4">
                                        <table className='w-100'>
                                            <tr className="table-form">
                                                <td>Street</td>
                                                <td>
                                                    <input
                                                        className='px-3'
                                                        onChange={handleChange}
                                                        type="text"
                                                        name="homeStreet"
                                                        id="homeStreet"
                                                        size="30"
                                                        maxlength="30"
                                                        placeholder="5th Avenue"
                                                    />
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>Building</td>
                                                <td className="ml-5">
                                                    <input
                                                        className='px-3'
                                                        onChange={handleChange}
                                                        type="text"
                                                        name="homeBuilding"
                                                        id="homeBuilding"
                                                        size="30"
                                                        maxlength="30"
                                                        placeholder="Suite G-111"
                                                    />
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>Zip</td>
                                                <td className="ml-5">
                                                    <input
                                                        className='px-3'
                                                        onChange={handleChange}
                                                        type="number"
                                                        name="homeZip"
                                                        id="homeZip"
                                                        size="30"
                                                        maxlength="30"
                                                        placeholder="12345"
                                                    />
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>City,State</td>
                                                <td className="ml-5">
                                                    <input
                                                        className='px-3'
                                                        onChange={handleChange}
                                                        type="text"
                                                        name="homeCity"
                                                        id="homeCity"
                                                        size="30"
                                                        maxlength="30"
                                                        placeholder="New York, NY"
                                                    />
                                                </td>
                                            </tr>
                                        </table>

                                    </div>
                                </div>
                            </div>
                            <button onClick={() => handleDatabaseUpdate()} className='btn main-button my-4 d-block ml-auto'>Save and Exit</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Profile;