/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import AnsweredQuestionCard from '../../components/AnsweredQuestionCard'
import DashboardNav from '../../components/DashboardNav'
import QuestionCard from '../../components/QuestionCard'
import { UserContext } from '../../contextApi/AppContext'
import './Dashboard.css'
import Documents from './Documents'
import ScrollToBottom from 'react-scroll-to-bottom';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
const axios = require('axios');


function DashBoardQuestion() {
    const { allData, allQuestion, setAllQuestion, questionDatabase, answeredQues, setAnsweredQues, loanType, unAnsweredQuestion, userInfo, dealDate, setUserInfo, setDealDate, setUnAnsweredQues, setIsUpdated, setLoanType, setConditionals, conditionals } = useContext(UserContext)
    const [currentQues, setCurrentQues] = useState(questionDatabase[answeredQues])
    const [inputVal, setInputVal] = useState("")
    const [img, setImg] = useState(null)
    const [document, setDocument] = useState(false)
    const [documentsNum, setDocumentsNum] = useState(allQuestion.filter(ques => ques?.hasOwnProperty("fileInput")) || [])
    const history = useHistory()
    const location = useLocation()
    const icon = loanType === 'Business' ? 'fas fa-business-time' : 'far fa-building';
    const [showModal, setShowModal] = useState(false)

    useEffect(() => {
        const filteredData = allData?.find(leadData => leadData?._id === location?.pathname?.split('/')[2])
        sessionStorage.setItem('allData', JSON.stringify(filteredData))
        setLoanType(filteredData?.allQuestion[0]?.value)
        setAllQuestion(filteredData?.allQuestion)
        setUserInfo(filteredData?.userInfo)
        setDealDate(filteredData?.date)
        setUnAnsweredQues(filteredData?.allQuestion?.filter(ques => ques?.value === 'Skipped'))
        setAnsweredQues(filteredData?.allQuestion?.length)
    }, [location?.pathname?.split('/')[2]])

    useEffect(() => {
        setDocumentsNum(allQuestion.filter(ques => ques?.value === 'Skipped' || ques?.isUploaded === 'Uploaded'))
    }, [allQuestion?.length])


    const handleAnswer = (currentQuestion, value) => {
        if (currentQuestion?.ques === 'Is the property under contract?') {
            setConditionals({ ...conditionals, isContract: value })
            setAllQuestion([...allQuestion, { ques: currentQuestion?.ques, value }])
            setAnsweredQues(answeredQues + 1)
            sessionStorage.setItem('conditionals', JSON.stringify({ ...conditionals, isContract: value }))
        }
        else if (currentQuestion?.input && value.length !== 0) {
            if (currentQuestion?.hasOwnProperty('money')) {
                setAllQuestion([...allQuestion, { ques: currentQuestion?.ques, value: `$ ${value}` }])
                setInputVal("")
                setAnsweredQues(answeredQues + 1)
            }
            else {
                setAllQuestion([...allQuestion, { ques: currentQuestion?.ques, value }])
                setInputVal("")
                setAnsweredQues(answeredQues + 1)
            }
        }
        else if (!currentQuestion?.input && !currentQuestion?.fileInput) {
            setAllQuestion([...allQuestion, { ques: currentQuestion?.ques, value }])
            if (answeredQues + 1 !== questionDatabase?.length) {
                setAnsweredQues(answeredQues + 1)
            }

        }
        else if (currentQuestion?.fileInput) {
            if (value === 'Skipped') {
                setAllQuestion([...allQuestion, { ques: currentQuestion?.ques, value, isUploaded: 'Not Uploaded' }])
                if (answeredQues + 1 !== questionDatabase?.length) {
                    setAnsweredQues(answeredQues + 1)
                }
            }
            else {
                setAllQuestion([...allQuestion, { ques: currentQuestion?.ques, value, isUploaded: 'Uploaded' }])
                setAnsweredQues(answeredQues + 1)
            }
        }
    }



    const handleNumberInput = e => {
        setInputVal(parseInt(e.target.value.replace(/,/g, ''), 10).toLocaleString())
    }

    const handleAddressInput = e => {
        setInputVal(e.target.value)
    }

    const removeInput = e => {
        e.target.value = ""
    }

    useEffect(() => {
        setCurrentQues(questionDatabase[answeredQues])
    }, [answeredQues, questionDatabase])

    const handleImageUpload = e => {
        setImg(e.target.files[0]);
    };


    const handleImageSubmit = (currQues) => {
        const imageData = new FormData();
        imageData.set('key', 'e97c27e983eb562435d83aa603736597');
        imageData.append('image', img);
        axios
            .post('https://api.imgbb.com/1/upload', imageData)
            .then(result => {
                if (result?.data?.data?.display_url) {
                    setImg(null)
                    handleAnswer(currQues, result?.data?.data?.display_url)
                }
            })
    }


    const handleDatabase = () => {
        if (location?.pathname?.split('/')[2] !== undefined) {
            fetch(`https://salura-capital-backend.herokuapp.com/updateLeads?_id=${location?.pathname?.split('/')[2]}`, {
                method: 'PATCH',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ userInfo, allQuestion, date: dealDate })
            })
                .then(res => {
                    if (res) {
                        sessionStorage.setItem('allData', JSON.stringify({ userInfo, allQuestion, date: dealDate }))
                        setAllQuestion(JSON.parse(sessionStorage.getItem('allData'))?.allQuestion)
                        setIsUpdated(true)
                        history.replace('/dashboard')
                    }
                })
        }
    }

    const handleRedirect = () => {
        setShowModal(false);
        history.push('/dashboard')
    }



    return (
        <section className='bg-cream'>
            <DashboardNav />
            <div className='container modal-container'>
                <div className="row py-3 mt-md-4">
                    <div className="col-lg-3 px-md-0 px-3">
                        <div className="d-flex mb-3 align-items-center">
                            <i className={`${icon} mr-4 icon`}></i>
                            <p className="text-left text-color mb-0">
                                <span className='h6'>{loanType === 'Business' ? `$${allQuestion[1]?.value} Business Loan` : `$${parseInt(allQuestion[3]?.value?.split(" ")[1]) / 1000}K ${allQuestion[1]?.value} Loan`}</span>
                            </p>
                        </div>
                        <div className="row align-items-center">
                            <div className='col-10 px-0'>
                                <div className="progress">
                                    <div
                                        className="progress-bar"
                                        style={{ width: `${(allQuestion?.length - unAnsweredQuestion?.length) / questionDatabase?.length * 100}%` }}
                                        role="progressbar"
                                        aria-valuenow="25"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                    ></div>
                                </div>
                            </div>
                            <div className='col-2'>
                                <span className='text-dark h6'>{parseInt((allQuestion?.length - unAnsweredQuestion?.length) / questionDatabase?.length * 100)}%</span>
                            </div>
                        </div>

                        <button onClick={() => handleDatabase()} className='btn main-button d-block mx-auto my-4'>Save and Exit</button>

                    </div>

                    <div className="col-lg-9 px-md-5 px-2">
                        <ul className="nav">
                            <li className="nav-item">
                                <a onClick={() => setDocument(false)} className='nav-link h5 pointer text-dark mb-0'>Questions</a>
                            </li>
                            <li className="nav-item">
                                <a onClick={() => setDocument(true)} className='nav-link h5 pointer text-dark mb-0 d-inline-block px-0'>Documents</a> <span className="badge badge-primary">{documentsNum?.length}</span>
                            </li>
                        </ul>
                        <hr className='mx-3' />

                        <ScrollToBottom id='scrollContainer' className='overflow-auto question-section h-100'>
                            {
                                document &&
                                <>
                                    <div className='p-3 '>
                                        <h2 className='text-dark mb-4'>Documents to Upload</h2>
                                        <p>{loanType === 'Business' ? `Property` : `Business`}-related documents</p>
                                    </div>

                                    <div className='row justify-content-center'>
                                        {
                                            documentsNum.map(data =>
                                                <div className='col-10 col-sm-6 col-lg-4 py-3'>
                                                    <Documents data={data} />
                                                </div>
                                            )
                                        }
                                    </div>
                                </>

                            }
                            {
                                !document &&
                                <>
                                    {
                                        answeredQues > 1 &&
                                        allQuestion?.slice(0, answeredQues).map((ques, index) =>
                                            <AnsweredQuestionCard img={img} setImg={setImg} handleImageUpload={handleImageUpload} handleDatabase={handleDatabase} ques={ques} index={index + 1} handleAnswer={handleAnswer} handleNumberInput={handleNumberInput} handleAddressInput={handleAddressInput} removeInput={removeInput} inputVal={inputVal} setInputVal={setInputVal} />
                                        )
                                    }

                                    {
                                        currentQues?.hasOwnProperty('ques') &&
                                        <QuestionCard setShowModal={setShowModal} handleDatabase={handleDatabase} img={img} handleImageSubmit={handleImageSubmit} handleImageUpload={handleImageUpload} ques={currentQues} index={answeredQues + 1} handleAnswer={handleAnswer} handleAddressInput={handleAddressInput} removeInput={removeInput} handleNumberInput={handleNumberInput} inputVal={inputVal} />
                                    }
                                </>

                            }
                        </ScrollToBottom>
                    </div>
                </div>
                {
                    showModal &&
                    <Modal open={showModal} onClose={handleRedirect} center>
                        <h2>Thank You!</h2>
                        <p>
                            You have sumbitted your response. You have answered all the question. Our team will go through this and we will get back to you shortly with our result.
                        </p>
                    </Modal>
                }
            </div>
        </section>
    )
}

export default DashBoardQuestion
