/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useContext } from 'react'
import upload from '../../assets/uploaded.svg'
import notUpload from '../../assets/notUpload.svg'
import { useLocation } from 'react-router';
import './Dashboard.css'
import { UserContext } from '../../contextApi/AppContext';

const axios = require('axios');


function Documents({ data }) {
    const { allQuestion, userInfo, dealDate, setAllQuestion, setIsUpdated } = useContext(UserContext)
    const location = useLocation()

    const handleImageSubmit = (e) => {
        const imageData = new FormData();
        imageData.set('key', 'e97c27e983eb562435d83aa603736597');
        imageData.append('image', e.target.files[0]);
        axios
            .post('https://api.imgbb.com/1/upload', imageData)
            .then(result => {
                if (result?.data?.data?.display_url) {
                    const updatedQuestion = allQuestion.map(ques => {
                        if (ques?.ques === data?.ques) {
                            return { ques: data?.ques, value: result?.data?.data?.display_url, isUploaded: 'Uploaded' }
                        }
                        else {
                            return ques
                        }
                    })

                    fetch(`https://salura-capital-backend.herokuapp.com/updateLeads?_id=${location?.pathname?.split('/')[2]}&link=true`, {
                        method: 'PATCH',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ userInfo, date: dealDate, allQuestion: updatedQuestion })
                    })
                        .then(res => {
                            if (res) {
                                setIsUpdated(true)
                                sessionStorage.setItem('allData', JSON.stringify({ userInfo, date: dealDate, allQuestion: updatedQuestion }))
                                setAllQuestion(updatedQuestion)
                                window.location.reload()
                            }
                        })
                }
            })
    }

    return (
        <div className={`card doc ${data?.value === 'Skipped' ? '' : 'card-bg'}`} >
            {
                data?.value === 'Skipped' ?
                    <>
                        <label for="file-upload" className="d-block p-2">
                            <div className='px-0 input-container w-100'>
                                <img className="card-img-top w-50 d-block mx-auto p-3" src={notUpload} alt="Card image cap" />
                            </div>
                        </label>

                        <input id="file-upload" type="file" onChange={handleImageSubmit} />
                    </>
                    :
                    <>

                        <img className="card-img-top w-50 d-block mx-auto py-3" src={upload} alt="Card image cap" />

                    </>
            }

            <h6 className="card-title px-2 mb-0">{data?.ques}</h6>
            <div className={`card-footer ${data?.value === 'Skipped' ? '' : 'card-bg'}`}>
                <h6 className='text-center small '>{data?.value === 'Skipped' ? 'Not yer uploaded' : 'Uploaded'}</h6>
            </div>
        </div>
    )
}

export default Documents
