export const BusinessRefinance = [
    {
        ques: 'What type of property are you inquiring about?',
        subtxt: 'If the property is a mix of multifamily and other uses such as office or retail, please select "Multifamily" as your property type.',
        options: [
            {
                icon: 'far fa-building',
                value: 'Multi-family',
            },
            {
                icon: 'fas fa-briefcase',
                value: 'Office',
            },
            {
                icon: 'fas fa-industry',
                value: 'Industrial / Warehouse',
            },
            {
                icon: 'fas fa-store',
                value: 'Retail',
            },
            {
                icon: 'fas fa-ellipsis-h',
                value: 'Other',
            },
        ]
    },
    {
        ques: 'Will the property be Owner Occupied?',
        subtxt: 'If more than 51% of the rentable SF will be owner occupied, please select Owner Occupied.',
        options: [
            {
                value: 'Owner Occupied',
            },
            {
                value: 'No',
            },
        ]
    },
    {
        ques: 'What is the primary purpose of this loan?',
        options: [
            {
                value: 'Acquisition',
            },
            {
                value: 'Refinance',
            },
            {
                value: 'Construction',
            },
        ]
    },
    {
        ques: 'What is the requested loan amount?',
        subtxt: "If you aren't entirely sure, please make your best educated guess.",
        input: true,
        money: true
    },
    {
        ques: 'What is the approximate cost basis of the property?',
        subtxt: "Your best estimate will be fine.",
        input: true,
        money: true
    },
    {
        ques: 'Please provide property address',
        input: true,
    },
    {
        ques: 'How many rentable SF is the property?',
        input: true,
        skip: true
    },
    {
        ques: 'Are you planning any capex or renovation work?',
        options: [
            {
                value: 'Yes',
            },
            {
                value: 'No',
            },
        ],
        skip: true
    },
    {
        ques: 'Do you have a budget?',
        subtxt: "Drag document here or press button.",
        fileInput: true,
        skip: true
    },
    {
        ques: 'Please upload a detailed rent roll',
        subtxt: "Please include unit mix and square footage if available. Excel is preferred. The more detailed the better. Drag document here or press button.",
        fileInput: true,
        skip: true
    },
    {
        ques: 'Please upload a trailing 12-month, detailed P&L.',
        subtxt: "Excel is preferred. The more detailed the better. Drag document here or press button",
        fileInput: true,
        skip: true
    },
    {
        ques: 'Do you have a bio or resume prepared the highlights your experience?',
        subtxt: "Drag document here or press button.",
        fileInput: true,
        skip: true
    },
]