/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { useContext, useEffect, useState } from 'react'
import AnsweredQuestionCard from '../../components/AnsweredQuestionCard'
import ContactDetails from '../../components/ContactDetails'
import QuestionCard from '../../components/QuestionCard'
import './SignUp.css'
import { UserContext } from '../../contextApi/AppContext'
import { multiFamily } from '../../Database/multiFamily'
import { propertyQuestion } from '../../Database/propertyQuestion'
import '../Home/HomePage.css'
import ScrollToBottom from 'react-scroll-to-bottom';
import logo from '../../assets/logo.png'
import { useHistory } from 'react-router'

function SignUp() {
    const { allQuestion, setAllQuestion, setLoanType, userInfo, setAllData, setConditionals, conditionals } = useContext(UserContext)
    const [question, setQuestion] = useState([])
    const [quesIndex, setQuesIndex] = useState(1)
    const [currentQues, setCurrentQues] = useState({})
    const [inputVal, setInputVal] = useState("")
    const history = useHistory()

    const handleAnswer = (currentQuestion, value) => {
        if (value === 'Multi-family') {
            setQuestion(multiFamily)
            setAllQuestion([...allQuestion, { ques: currentQuestion?.ques, value }])
            setQuesIndex(quesIndex + 1)
            setLoanType(value)
        }
        else if (value === 'Office' || value === 'Industiral / Warehouse' || value === 'Retail' || value === 'Other') {
            setQuestion(propertyQuestion)
            setAllQuestion([...allQuestion, { ques: currentQuestion?.ques, value }])
            setQuesIndex(quesIndex + 1)
            setLoanType(value)
        }
        else if (value === 'Acquisition' || value === 'Refinance' || value === 'Construction') {

            setAllQuestion([...allQuestion, { ques: currentQuestion?.ques, value }])
            setQuesIndex(quesIndex + 1)
            setConditionals({ ...conditionals, type: value })
            sessionStorage.setItem('conditionals', JSON.stringify({ ...conditionals, type: value }))
        }
        else if (currentQuestion?.input && value.length !== 0) {
            if (currentQuestion?.hasOwnProperty('money')) {
                setAllQuestion([...allQuestion, { ques: currentQuestion?.ques, value: `$ ${value}` }])
                setInputVal("")
                setQuesIndex(quesIndex + 1)
            }
            else {
                setAllQuestion([...allQuestion, { ques: currentQuestion?.ques, value }])
                setInputVal("")
                setQuesIndex(quesIndex + 1)
            }
        }
        else if (!currentQuestion?.input) {
            setAllQuestion([...allQuestion, { ques: currentQuestion?.ques, value }])
            setQuesIndex(quesIndex + 1)
        }
    }

    const removeInput = e => {
        e.target.value = ""
    }


    const handleNumberInput = e => {
        setInputVal(parseInt(e.target.value.replace(/,/g, ''), 10).toLocaleString())
    }


    useEffect(() => {
        if (quesIndex < 2) {
            setCurrentQues(multiFamily[0])
        }
        else {
            setCurrentQues(question[quesIndex - 1])
        }

    }, [quesIndex])



    useEffect(() => {
        if (userInfo?.email) {
            setAllQuestion([])
            sessionStorage.setItem('allData', JSON.stringify({ userInfo }))
        }
    }, [])


    const handleSubmit = () => {
        fetch('https://salura-capital-backend.herokuapp.com/postLeads', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ userInfo, allQuestion, date: new Date().toDateString() })
        })
            .then(res => {
                if (res) {
                    fetch(`https://salura-capital-backend.herokuapp.com/allLeads?email=${userInfo?.email}`)
                        .then(res => res.json())
                        .then(resData => {
                            setAllData(resData)
                            sessionStorage.setItem('database', JSON.stringify(resData))
                            sessionStorage.setItem('allData', JSON.stringify(resData[resData?.length - 1]))
                            history.replace(`/myDeal/${resData[resData?.length - 1]?._id}`)
                        })
                }
            })
    }


    return (
        <section className='bg-img'>
            <div className="row justify-content-between">
                <div className="col-lg-4 left-sidebar">
                    <div className="sidebar d-flex flex-column justify-content-between  " >
                        <img src={logo} className='logo w-35 my-2 mx-5' alt='logo' />
                    </div>
                </div>

                <div className="col-lg-8 px-0">
                    <div className='container px-0'>
                        <ScrollToBottom className='container pt-4 overflow-auto question-section h-100'>
                            {
                                quesIndex > 1 &&
                                allQuestion?.map((ques, index) =>
                                    <AnsweredQuestionCard index={index + 1} ques={ques} />
                                )
                            }

                            {
                                currentQues?.hasOwnProperty('ques') && !currentQues?.hasOwnProperty('isContact') &&
                                <QuestionCard ques={currentQues} index={quesIndex} handleAnswer={handleAnswer} handleNumberInput={handleNumberInput} removeInput={removeInput}  inputVal={inputVal} />
                            }
                            {
                                currentQues?.hasOwnProperty('isContact') ?
                                    !JSON.parse(sessionStorage.getItem('allData'))?.userInfo?.email ?
                                        <ContactDetails ques={currentQues} index={quesIndex} />
                                        :
                                        <button onClick={handleSubmit} className='btn main-button d-block ml-auto my-4'>Submit</button>
                                    :
                                    <></>
                            }
                        </ScrollToBottom>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SignUp
