import React from 'react'
import './QuestionOption.css'

function QuestionOption({ value, icon, currentValue }) {
    return (
        <div className={`option my-3 ${currentValue === value ? 'bg-blue' : ''}`}>
            <div className='row p-3 align-items-center'>
                {
                    icon !== "" && icon !== undefined &&
                    <div className='col-1'>
                        <i className={icon}></i>
                    </div>
                }
                <div className={`${icon !== "" && icon !== undefined ? 'col-10' : 'col-12'}`}>
                    <h6 className='mb-0'>{value}</h6>
                </div>
            </div>
        </div>
    )
}

export default QuestionOption
