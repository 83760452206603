import React, { useEffect, useState } from 'react'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';


function DownloadExcel() {
    const [database, setDatabase] = useState([])
    const [password, setPassword] = useState("")
    const [isAuth, setIsAuth] = useState(false)
    const screenWidth = window.innerWidth;


    useEffect(() => {
        fetch('https://salura-capital-backend.herokuapp.com/allData')
            .then(res => res.json())
            .then(allData => setDatabase(allData))
    }, [])

    const handleChange = (e) => {
        setPassword(e.target.value)
    }

    const handleSubmit = () => {
        if (password === '50114400') {
            setIsAuth(true)
        }
    }


    const handleTable = () => {
        const reArrangedData = database?.map(data => {
            const finalData = data?.allQuestion?.map((info, index) => {
                return <tr className='text-center'>
                    <td>{index === 0 ? `${data?.userInfo?.firstName} ${data?.userInfo?.lastName}` : ""}</td>
                    <td>{index === 0 ? data?.userInfo?.email : ""}</td>
                    <td>{index === 0 ? data?.userInfo?.tel : ""}</td>
                    <td>{info?.ques}</td>
                    <td>{info?.value}</td>
                </tr>
            })
            return finalData
        })
        return reArrangedData;
    }
    return (
        <>
            {
                isAuth ?
                    <div className='container my-4'>
                    <h1 className='text-center'>Download Data as Excell Sheet</h1>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="download-table-xls-button btn btn-primary d-block mx-auto my-3"
                            table="table-to-xls"
                            filename="ALL-Leads"
                            sheet="tablexls"
                            buttonText="Download as XLS" />
                        <table id="table-to-xls" className='table-bordered table w-100 d-block mx-auto'>
                            <tr className='text-center'>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Telephone</th>
                                <th>Question</th>
                                <th>Answer</th>
                            </tr>
                            {
                                handleTable()
                            }
                        </table>
                    </div>
                    :
                    <div className={`input-icon container d-block mx-auto mt-5 ${screenWidth < 576 ? 'w-100' : 'w-50'}`}>
                        <h5 className='text-center'>Enter the password</h5>
                        <input onChange={handleChange} type="text" className='form-control input-field w-75 d-block mx-auto' />

                        <button onClick={handleSubmit} className='btn main-button d-block mx-auto my-2'>Enter</button>
                    </div>
            }
        </>
    )
}

export default DownloadExcel
